@import 'assets/scss/variables.module';

.container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.content {
  max-width: 900px;
  margin-top: 50px;
  padding: 0 1rem;
  padding-bottom: 50px;

  @include response-small {
    margin-top: 1rem;
  }

  p {
    margin-top: 25px;
  }

  a {
    color: $archidekt-orange;
    font-weight: bold;
    transition: filter 0.2s ease;

    &:hover {
      filter: brightness(1.1);
    }
  }
}
